import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import RequireAuth from './pages/Login/RequireAuth';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Navbar from './pages/shared/Navbar';
import ManageProducts from './pages/Dashboard/ManageProducts';
import AddProduct from './pages/Dashboard/AddProduct';
import NotFound from './pages/shared/NotFound/NotFound';
import ScrollToTop from './ScrollToTop';
import { useEffect, useState } from 'react';
import Loading from './pages/shared/Loading/Loading';
import AddReveiw from './pages/AddReveiw/AddReveiw';
import Contact from './pages/Contact/Contact';
import ManageReview from './pages/Dashboard/ManageReview';


function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [1000])
  }, [])

  return (loading ? <Loading loadingStatus="true"></Loading> :
    <div>
      <ScrollToTop></ScrollToTop>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<Navigate to="/home"></Navigate>} />
        <Route path="/home" element={<Home />} />
        <Route path="/product/:id" element={<ProductDetails />}></Route>
        <Route path="/add-review" element={<AddReveiw />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} >
          <Route index element={<AddProduct />}></Route>
          <Route path='manageProducts' element={<ManageProducts />}></Route>
          <Route path='manageReview' element={<ManageReview />}></Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
