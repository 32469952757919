import React, { useEffect, useState } from 'react';
import Loading from '../shared/Loading/Loading';
import SingleProduct from '../SingleProduct/SingleProduct';


const Products = () => {

    const [parts, setParts] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch("https://onlinefooddelivery.agropackagingltd.com/products")
            .then(res => res.json())
            .then(data => {
                const partsSlice = data?.slice(-6);
                const partsReverse = partsSlice?.reverse();
                setParts(partsReverse)
                setLoading(false);
            })
    }, [])


    return (
        <div id='products'>
            <div className='customContainer'>
                <h2 className='text-3xl sm:text-4xl text-center pt-[80px] pb-[85px] text-gray-900 font-bold titleLine'>Our Products</h2>
                {loading ? <Loading loadingStatus="true"></Loading> :
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-12'>
                        {parts?.map(singleParts => <SingleProduct key={singleParts._id} singleParts={singleParts}></SingleProduct>)}
                    </div>
                }
            </div>
        </div>
    )
};

export default Products;