import React from 'react';
import Banner from './Banner';
import AllParts from './AllParts';
import Reveiw from './Reveiw';
import Works from './Works';
import Footer from "../shared/Footer"
import Team from './Team';
import Gallery from './Gallery';

const Home = () => {
    return (
        <div>
            <Banner />
            <AllParts />
            <Works />
            <Reveiw />
            <Gallery />
            <Team />
            <Footer />
        </div>
    );
};

export default Home;