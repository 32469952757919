import React from 'react';
import { EffectFade, Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import slider1 from "../../assets/Banner/slide-1.webp"
import slider3 from "../../assets/Banner/slide-3.webp"
import slider4 from "../../assets/Banner/slide-4.webp"


const Banner = () => {

    const sliders = [
        slider1,
        slider3,
        slider4
    ]

    return (
        <Swiper
            modules={[EffectFade, Navigation, A11y, Autoplay]}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            effect={"fade"}
            loop={true}
            navigation
            slidesPerView={1}>
            {sliders?.map((slider,i) =>
                <SwiperSlide key={i} className='relative'>
                    <img className='bannerImage' src={slider} alt="" />
                    <div className='hidden sm:block absolute bannerImagePositionSet'>
                        <h2 className='text-center text-4xl leading-[1.3em] sm:text-6xl font-bold text-white bannerTitleStyle smallDeviceTitle'>Agro Packaging Limited</h2>
                        <p className='pt-5 sm:pt-6 px-5 sm:px-14 text-center text-white'>Crafting Excellence in Packaging for Every Product. Innovative, Sustainable, and Reliable Packaging Solutions Tailored to Your Brand's Needs. Elevate Your Packaging Game Today!</p>
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
    );
};

export default Banner;