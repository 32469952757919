import React from 'react';
import { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import { BsWhatsapp } from "react-icons/bs"
import { TfiEmail } from "react-icons/tfi"


const Team = () => {

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetch("team.json")
      .then(res => res.json())
      .then(data => setTeams(data))
  }, [])

  return (
    <div className='customContainer mt-3 mb-20'>
      <h2 className={`text-3xl sm:text-4xl text-gray-900 text-center pt-24 font-bold titleLine`}>Our Amazing Team</h2>
      <Swiper className='!px-3'
        modules={[Pagination, A11y, Autoplay]}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}
        breakpoints={{
          // when window width is >= 640px
          200: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        pagination={{ clickable: true }}>
        {teams?.map((t, i) => <SwiperSlide key={i}>
          <div className='h-fit lg:h-60 bg-white team-shadow p-6 sm:p-8 rounded-lg mt-20 sm:mt-24'>
            <h3 className='text-xl font-semibold text-gray-900'>{t.name}</h3>
            <h5 className='text-sm font-medium text-gray-700 mb-2'>{t.designation}</h5>
            <p className='text-sm text-gray-800'>{t.description}</p>
            {t?.whatsapp && <div className='flex items-center text-sm text-gray-700 mt-4'>
              <BsWhatsapp className='text-primary' />
              <span className='ml-2'>{t.whatsapp}</span>
            </div>}
            {t?.email && <div className='flex items-center text-sm text-gray-700 mt-1.5'>
              <TfiEmail className='text-primary' />
              <span className='ml-2'>{t.email}</span>
            </div>}
          </div>
        </SwiperSlide>)}
        <div className='w-full h-24 none'>
        </div>
      </Swiper>
    </div>
  );
};

export default Team;