import { useEffect, useState } from 'react';

const useToken = (user) => {

    const [token, setToken] = useState("");

    useEffect(() => {

        const email = user?._tokenResponse?.email;
        const currentUser = { email };

        if (email) {
            fetch(`https://onlinefooddelivery.agropackagingltd.com/user/${email}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(currentUser)
            })
                .then(res => res.json())
                .then(data => {
                    const accessToken = data.token
                    localStorage.setItem("accessToken", accessToken)
                    setToken(accessToken);
                })
        }
    }, [user])

    return [token]
};

export default useToken;