import React from 'react';
import { useNavigate } from 'react-router-dom';
import { textSlice } from '../../utils/TextSlice';

const SingleProduct = ({ singleParts }) => {

    const { _id, name, images, description } = singleParts
    const navigate = useNavigate();

    return (
        <div className="card singlePartsBoxShadow">
            <figure><img className='w-60 h-60 mt-6' src={images[0]} alt="bags" /></figure>
            <div className="card-body text-black p-5">
                <h2 className="card-title font-medium">
                    {name}
                </h2>
                <p className='mb-1'>{textSlice(description, 115)}</p>
                <button onClick={() => navigate(`/product/${_id}`)} className="btn btn-primary text-white w-1/2 rounded-sm tracking-wider">Details</button>
            </div>
        </div>
    );
};

export default SingleProduct;
