import { faHouseChimney, faListCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, Outlet } from 'react-router-dom';
import auth from '../../firebase.init';
import Footer from '../shared/Footer';
import { GoCodeReview } from "react-icons/go"


const Dashboard = () => {

    const [user] = useAuthState(auth);

    return (
        <>
            <div className="drawer drawer-mobile mb-24">
                <input id="sidebar-open" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content sm:mr-5 sm:ml-5">
                    <Outlet></Outlet>
                </div>
                <div className="drawer-side">
                    <label htmlFor="sidebar-open" className="drawer-overlay"></label>
                    <ul className="menu p-4 overflow-y-auto w-60 border-r-2 bg-white sm:bg-transparent">
                        {user && <>
                            <li className='font-bold border-b-2 border-primary'><div><FontAwesomeIcon className='text-primary' icon={faHouseChimney}></FontAwesomeIcon> Dashboard</div></li>
                            <li className='border-b-2'><Link className='font-medium' to="/dashboard"> <FontAwesomeIcon className='text-primary' icon={faPlus}></FontAwesomeIcon>Add Product</Link></li>
                            <li className='border-b-2'><Link className='font-medium' to="/dashboard/manageProducts"> <FontAwesomeIcon className='text-primary' icon={faListCheck}></FontAwesomeIcon> Manage Product</Link></li>
                            <li className='border-b-2'><Link className='font-medium' to="/dashboard/manageReview"> <GoCodeReview className='text-primary text-lg'/> Manage Review</Link></li>
                        </>}
                    </ul>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Dashboard;