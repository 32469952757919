import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CiCircleRemove } from "react-icons/ci"


const AddReveiw = () => {

    const { register, handleSubmit, reset } = useForm();
    const imageStorageKey = "bbb41293b29baeed6436287ccb9bbf00";
    const [userPictureUrl, setUserPictureUrl] = useState("");

    const handleFileSelected = async (e) => {
        const file = Array.from(e.target.files)
        const image = file[0];
        const formData = new FormData();
        formData.append("image", image)
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`

        await fetch(url, {
            method: "POST",
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result.success) {
                    setUserPictureUrl(result.data.url);
                }
            })
    }


    const handleAddReview = (data) => {

        const newReview = {
            ...data,
            image: userPictureUrl,
            rating: parseInt(data.rating)
        }

        fetch(`https://onlinefooddelivery.agropackagingltd.com/reveiw`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newReview)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    toast.success(`Reveiw successfully Added`)
                    setUserPictureUrl("");
                    reset();
                } else {
                    toast.error(`Something went wrong. please try an other one`);
                }
            })
    }


    return (
        <div className='flex justify-center my-10'>
            <div className="max-w-lg max-h-fit add-review-shadow py-8 px-6 sm:p-10 mx-4 sm:mx-10">
                <h2 className='text-2xl text-center mb-6 font-bold'>Add Reveiw</h2>
                <div className=' bg-white rounded-2xl'>
                    <form onSubmit={handleSubmit(handleAddReview)}>
                        <div className="form-control mb-4">
                            <label htmlFor="name" className="label">
                                <span className="label-text font-medium">Full Name <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <input type="text" id='name' placeholder="Full Name" className="input input-bordered formInputDesgign"
                                {...register("name")} required />
                        </div>
                        <div className="form-control mb-4">
                            <label htmlFor='Address' className="label none">
                                <span className="label-text font-medium">Country <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <select id='Address' className="select formInputDesgign font-normal" {...register("Address")} required>
                                <option>Bangladesh</option>
                                <option>India</option>
                                <option>Pakistan</option>
                                <option>China</option>
                                <option>Japan</option>
                            </select>
                        </div>
                        <div className="form-control mb-6">
                            <label htmlFor='description' className="label">
                                <span className="label-text font-medium">Say Something About Our Company <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <textarea id='description' className="textarea textarea-bordered formInputDesgign h-24 leading-normal" placeholder="Say Someting ..." {...register("description")} required></textarea>
                        </div>
                        <div>
                            <p className='label-text font-medium mb-4'>Image</p>
                            <label className='bg-green-100 py-2 px-8 text-primary border font-medium border-primary rounded-md cursor-pointer' htmlFor="filePicker">
                                <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>  Upload Photo
                            </label>
                            <input className='input input-bordered formInputDesgign invisible h-6' type="file" onChange={handleFileSelected} id="filePicker" name='file' />
                            {userPictureUrl && <div className='w-fit flex items-center gap-3 p-1.5 shadow mb-8'>
                                <img className='w-6 h-6' src={userPictureUrl} alt="" />
                                <p className='text-sm '>Upload Image</p>
                                <CiCircleRemove onClick={() => setUserPictureUrl("")} className='ml-5 text-red-500 text-lg cursor-pointer' />
                            </div>}
                        </div>
                        <div className="form-control">
                            <label className="label pt-0">
                                <span className="label-text font-medium">Ratings <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <div className="rating gap-1.5">
                                <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-600" value="1" {...register('rating')} />
                                <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-600" value="2" {...register('rating')} />
                                <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-600" value="3" {...register('rating')} />
                                <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-600" value="4" {...register('rating')} />
                                <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-600" value="5" {...register('rating')} defaultChecked />
                            </div>
                        </div>
                        <div className='flex justify-center mt-12'>
                            <input className='btn btn-primary w-full sm:w-60 text-white' type="submit" value="Add Reveiw" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddReveiw;