import { faClock, faEarthAsia, faEnvelope, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import facebook from "../../assets/Footer/Vector.png"
import instagrame from "../../assets/Footer/Vector-1.png"
import linkdin from "../../assets/Footer/Vector-2.png"
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const year = new Date().getFullYear();
    const navigate = useNavigate();

    return (
        <footer className="bg-[#009849] text-white text-center sm:text-left px-5 sm:px-20">
            <div className="customContainer pb-5 pt-10 paddingDecrease">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 sm:gap-16 py-4">
                    <div>
                        <h6 className="text-base sm:text-lg uppercase font-medium">Contact Us</h6>
                        <ul className="list-unstyled text-center mt-4 sm:mt-5 mb-0 text-sm sm:text-base">
                            <div className='flex-none sm:flex items-start justify-center sm:justify-start gap-2.5 sm:gap-2 mb-3 sm:mb-0'>
                                <FontAwesomeIcon className='mt-2' icon={faEarthAsia}></FontAwesomeIcon> <li className="my-1">Location : BSCIC, Industrial Area, plot# A 27 & 28, Maria, Kishoreganj-2300, Bangladesh</li>
                            </div>
                            <div className='flex-none sm:flex items-center justify-center sm:justify-start gap-2.5 sm:gap-3 mb-3 sm:mb-0'>
                                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> <li className="my-1">Email : agropackagingltd@gmail.com</li>
                            </div>
                            <div className='flex-none sm:flex items-center justify-center sm:justify-start gap-2.5 sm:gap-3 mb-3 sm:mb-0'>
                                <FontAwesomeIcon icon={faMobileScreen}></FontAwesomeIcon> <li className="my-1">Mobile : 01746176862, 01983354391 </li>
                            </div>
                            <div className='flex-none sm:flex items-center justify-center sm:justify-start gap-2.5 sm:gap-3'>
                                <FontAwesomeIcon icon={faClock}></FontAwesomeIcon> <li className="my-1">Open : 7 days, 8:00am - 6:00pm</li>
                            </div>
                        </ul>
                    </div>
                    <div className="flex justify-center">
                        <div>
                            <h6 className="text-base sm:text-lg uppercase font-medium mb-4 sm:mb-5">Company</h6>
                            <ul className="list-unstyled mb-0 text-sm sm:text-base">
                                <li onClick={() => navigate("/login")} className="cursor-pointer mb-2">Admin</li>
                                <li className="mb-2">Brands</li>
                                <li className="mb-2">Contact Us</li>
                                <li className="mb-2">Privacy Policy</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h6 className="text-base sm:text-lg uppercase font-medium mb-4 sm:mb-5">About Us</h6>
                        <p className='text-sm sm:text-base mb-4'>Be the first to know about exciting our packaging products. Incradible packaging and much more.</p>
                        <p className='mt-5 mb-3'>Follow us on social networks</p>
                        <div className='flex justify-center sm:justify-start items-center gap-5'>
                            <div className='w-7'>
                                <a href="https://www.facebook.com/profile.php?id=61552952236275&mibextid=ZbWKwL" target='blank' rel='noreferrer'><img src={facebook} alt="" />
                                </a>
                            </div>
                            <div className='w-7'>
                                <a href="https://www.instagram.com/agropackagingltd/" target='blank' rel='noreferrer'><img src={instagrame} alt="" /> </a>
                            </div>
                            <div className='w-7'>
                                <a href="https://www.linkedin.com/in/agro-packaging-limited-22618029a/" target='blank' rel='noreferrer'><img src={linkdin} alt="" /> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="py-3">
                <div className="text-center">
                    <p className=" mb-0 py-2">Copyright © {year} Agro Packaging Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;