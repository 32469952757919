import React from 'react';
import { PropagateLoader  } from 'react-spinners';
import { css } from "@emotion/react";
import "./Loading.css";

const override = css`
  display:block;
  margin: 0px auto;
  border-color: "#009849";
`;

const Loading = (loadingStatus) => {
    return (
        <div className='loading'>
            <PropagateLoader  color={"#009849"} loading={loadingStatus} css={override} size={20} />
        </div>
    );
};

export default Loading;