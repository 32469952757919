import React, { useEffect, useState } from 'react';
import Footer from '../shared/Footer';
import Loading from '../shared/Loading/Loading';
import { useParams } from 'react-router-dom';
import CustomImageSlider from '../Home/CustomImageSlider';


const ProductDetails = () => {

  const { id } = useParams();
  const [singleParts, setSingleParts] = useState({});
  const { name, images, description } = singleParts;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(`https://onlinefooddelivery.agropackagingltd.com/product/${id}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setSingleParts(data)
        setLoading(false);
      })
      .catch(err => err)
  }, [id]);


  return (loading ? <Loading loadingStatus="true"></Loading> :
    <>
      <div className="customContainer mt-10 mb-24">
        <div className='flex justify-center'>
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center sm:w-9/12">
            <CustomImageSlider images={images} />
            <div className="card-body py-0 px-4 sm:px-0 text-gray-900">
              <h2 className="card-title">{name}</h2>
              <p className='mb-1'>{description}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ProductDetails;