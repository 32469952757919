import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


const CustomImageSlider = ({ images }) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="custom-image-slider">
      <div className="slider-container">
        <div className="image-container">
          <div className='flex justify-center mb-4'>
            <img src={images[currentImageIndex]} alt="Shoes" className="w-72 h-72" />
          </div>
          <button onClick={handlePrevClick} className="prev-button">
            <FiChevronLeft />
          </button>
          <button onClick={handleNextClick} className="next-button">
            <FiChevronRight />
          </button>
        </div>
        <div className="thumbnail-container mt-3">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomImageSlider;
