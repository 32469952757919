/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import roll_1 from "../../assets/Gallery/fabric roll/roll-1.webp"
import roll_2 from "../../assets/Gallery/fabric roll/roll-2.webp"
import roll_3 from "../../assets/Gallery/fabric roll/roll-3.webp"
import roll_4 from "../../assets/Gallery/fabric roll/roll-4.webp"
import roll_5 from "../../assets/Gallery/fabric roll/roll-5.webp"
import roll_6 from "../../assets/Gallery/fabric roll/roll-6.jpg"
import packaging_1 from "../../assets/Gallery/packaging/packaging-1.webp"
import packaging_2 from "../../assets/Gallery/packaging/packaging-2.webp"
import packaging_3 from "../../assets/Gallery/packaging/packaging-3.webp"
import packaging_4 from "../../assets/Gallery/packaging/packaging-4.webp"
import packaging_5 from "../../assets/Gallery/packaging/packaging-5.webp"
import packaging_6 from "../../assets/Gallery/packaging/packaging-6.webp"
import bag_1 from "../../assets/Gallery/bag/bag-1.webp"
import bag_2 from "../../assets/Gallery/bag/bag-2.webp"
import bag_3 from "../../assets/Gallery/bag/bag-3.webp"
import bag_5 from "../../assets/Gallery/bag/bag-5.webp"
import bag_6 from "../../assets/Gallery/bag/bag-6.webp"


const Gallery = () => {

    const roll = [
        roll_1,
        roll_2,
        roll_3,
        roll_4,
        roll_5,
        roll_6
    ]

    const packaging = [
        packaging_1,
        packaging_2,
        packaging_3,
        packaging_4,
        packaging_5,
        packaging_6
    ]

    const bag = [
        bag_1,
        bag_2,
        bag_3,
        bag_5,
        bag_6
    ]

    const tabs = [
        { id: 1, name: 'Fabric Roll' },
        { id: 2, name: 'Packaging' },
        { id: 3, name: 'Bags' },
    ];

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div id="faq" className="customContainer">
            <h2 className="text-3xl sm:text-4xl text-gray-900 text-center pt-16 pb-[75px] font-bold titleLine">Our Portfolio</h2>

            <div className="w-full">
                <div className="flex justify-center space-x-5">
                    {tabs.map((tab) => (
                        <div
                            key={tab.id}
                            onClick={() => handleTabClick(tab.id)}
                            className={`cursor-pointer p-2 px-3 sm:px-4 ${activeTab === tab.id && 'text-primary border-b-2 border-primary transition-colors'}`}
                        >
                            {tab.name}
                        </div>
                    ))}
                </div>

                <div className="mt-10 min-h-[300px]">
                    {activeTab === 1 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {roll.map((image, index) => (
                                <div key={index} className="bg-white rounded-lg overflow-hidden">
                                    <div className="relative aspect-w-1 aspect-h-1">
                                        <img
                                            className="w-full h-[280px]"
                                            src={image}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {packaging.map((image, index) => (
                                <div key={index} className="bg-white rounded-lg overflow-hidden">
                                    <div className="relative aspect-w-1 aspect-h-1">
                                        <img
                                            className="w-full h-[280px]"
                                            src={image}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {activeTab === 3 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {bag.map((image, index) => (
                                <div key={index} className="bg-white rounded-lg overflow-hidden">
                                    <div className="relative aspect-w-1 aspect-h-1">
                                        <img
                                            className="w-full h-[280px]"
                                            src={image}
                                            alt=''
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Gallery;