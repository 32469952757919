import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { signOut } from 'firebase/auth';
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/logo.png";
import { useLocation } from 'react-router-dom';

const Navbar = () => {

    const [user] = useAuthState(auth);
    const [navbar, setNavbar] = useState(false);
    const { pathname, hash } = useLocation();
    const currentPath = pathname + hash;
    const noScroll = "navbar py-2.5 bg-white text-black sticky top-0 z-[1030]";
    const scrolly = "navbar py-2.5 bg-white text-black sticky top-0 z-[1030] navbarScrollBoxShadow";

    const handleLogOut = () => {
        signOut(auth);
        localStorage.removeItem("accessToken");
    }

    const scrollWindow = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollWindow)

    const menuItem = <>
        <li>
            <Link className={`focus:text-black ${currentPath === "/home" && "text-primary transition-colors"}`} smooth to="/home#">Home</Link>
        </li>
        <li>
            <Link className={`focus:text-black ${currentPath === "/home#products" && "text-primary transition-colors"}`} smooth to="/home#products">Products</Link>
        </li>
        {
            user && <li>
                <Link className={`focus:text-black ${currentPath === "/dashboard" && "!text-primary transition-colors"}`} smooth to="/dashboard">Dashboard</Link>
            </li>
        }
        <li>
            <Link className={`focus:text-black ${currentPath === "/home#testimonial" && "text-primary transition-colors"}`} smooth to="/home#testimonial">Reveiws</Link>
        </li>
        <li>
            <Link className={`focus:text-black ${currentPath === "/add-review" && "!text-primary transition-colors"}`} smooth to="/add-review">Add Reveiw</Link>
        </li>
        <li>
            <Link className={`focus:text-black ${currentPath === "/contact" && "!text-primary transition-colors"} ${user ? "pr-8" : "pr-0"}`} smooth to="/contact">Contact</Link>
        </li>

        {
            user &&
            <button onClick={handleLogOut} className="btn btn-secondary capitalize text-white border-0 rounded-sm">Log Out</button>
        }

    </>

    return (
        <div className={navbar ? scrolly : noScroll}>
            <div className="customContainer paddingDecrease uppercase">
                <div className="flex justify-between w-full sm:w-4/12">
                    <Link className='flex items-center pl-4 sm:pl-0' smooth to="/home">
                        <img className='w-12 sm:w-14' src={logo} alt="" />
                        <div className='ml-1 font-bold capitalize'>
                            <p className='text-base sm:text-lg text-primary'>Agro Packaging Limited</p>
                            <span className='text-sm sm:text-base text-secondary'>এগ্রো প্যাকেজিং লিমিটেড</span>
                        </div>
                    </Link>
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-5 p-2 shadow bg-base-100 rounded-sm w-52 right-14 font-medium">
                            {menuItem}
                        </ul>
                    </div>

                </div>
                <div className="navbar-end hidden lg:flex sm:w-8/12">
                    <ul className="menu menu-horizontal p-0 font-medium">
                        {menuItem}
                    </ul>
                </div>
                {/* <div className="navbar-end text-right mr-1.5 sm:hidden">
                    <label tabIndex="1" htmlFor="sidebar-open" className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                </div> */}
            </div>
        </div>
    );
};

export default Navbar;