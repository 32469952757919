import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from '../shared/Loading/Loading';
import { BsFillTrash3Fill } from 'react-icons/bs'
import { textSlice } from '../../utils/TextSlice';


const ManageProducts = () => {

  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch("https://onlinefooddelivery.agropackagingltd.com/products")
      .then(res => res.json())
      .then(data => {
        setProducts(data)
        setLoading(false);
      })

  }, [productCount])


  const handleProductDelete = (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://onlinefooddelivery.agropackagingltd.com/product/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("accessToken")}`
          }
        })
          .then(res => res.json())
          .then(data => {
            if (data.acknowledged) {
              Swal.fire(
                'Deleted!',
                'Your Product has been deleted.',
                'success'
              )
              setProductCount(productCount + 1);
            }
          })
      }
    })
  }

  return (loading ? <Loading loadingStatus="true"></Loading> :
    <div className='m-3 sm:m-0 mb-10'>
      <h2 className='py-5 text-2xl font-bold'>Manage Products</h2>
      <div className="overflow-x-auto h-fit">
        <table className="table w-full border border-gray-300">
          <thead>
            <tr>
              <th className="text-center !rounded-none bg-primary text-white border border-primary border-r-white">#</th>
              <th className="text-center bg-primary text-white border border-primary border-r-white">Image</th>
              <th className="bg-primary text-white border border-primary border-r-white">Name</th>
              <th className="bg-primary text-white border border-primary border-r-white">Description</th>
              <th className="bg-primary text-white border border-primary border-r-white">Entry Date</th>
              <th className="text-center !rounded-none bg-primary text-white border border-primary">Action</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((p, i) => <tr key={p?._id}>
              <th className="text-center border border-gray-300">{i + 1}</th>
              <td className="border border-gray-300">
                <div className='flex justify-center'>
                  <img className='w-7 h-7 rounded-full' src={p?.images[0]} alt="" />
                </div>
              </td>
              <td className="border border-gray-300">{p?.name}</td>
              <td className="border border-gray-300">{textSlice(p?.description, 25)}</td>
              <td className="border border-gray-300">20 August, 2023</td>
              <td className="text-center border border-gray-300">
                <div className="flex justify-center items-center">
                  <span onClick={() => handleProductDelete(p?._id)} className="p-2 rounded-full hover:bg-gray-100 cursor-pointer"><BsFillTrash3Fill className=" text-red-600" /></span>
                </div>
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageProducts;