import React from 'react';
import Footer from '../shared/Footer';
import { BsWhatsapp } from "react-icons/bs"
import { TfiEmail } from "react-icons/tfi"

const Contact = () => {
  return (
    <div>
      <div className='summaryBackgroundImageSet mb-32'>
        <h2 className='text-5xl py-36 text-white text-center'>Contact Us</h2>
      </div>
      <div className='customContainer mb-24'>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div>
            <h2 className='text-3xl text-primary pb-3'>Contact Person</h2>
            <h3 className='text-lg text-gray-500 pb-3.5'>
              Md. Shafiqul Islam <br />
              <p className='flex gap-1.5 items-center'><BsWhatsapp className='text-lg' />01746176862</p>
              <p className='flex gap-1.5 items-center'><TfiEmail className='text-base' />shafiq.md.apl@gmail.com</p>
            </h3>
            <h3 className='text-lg text-gray-500 pb-5'>
              Md Junayad  <br />
              <p className='flex gap-1.5 items-center'><BsWhatsapp className='text-lg' />01983354391</p>
              <p className='flex gap-1.5 items-center'><TfiEmail className='text-base' />apljunayad@gmail.com</p>
            </h3>
          </div>
          <div>
            <h2 className='text-3xl text-primary pb-3'>Head Office</h2>
            <p className='max-w-[300px] text-base text-gray-500 pb-5'>641 Haji Shahabuddin Market (1st  Floor) Boro bazar Kishoreganj. <br />
              Mobile : 01746176862, 01983354391</p>
          </div>
          <div>
            <h2 className='text-3xl text-primary pb-3'>Factory</h2>
            <p className='max-w-[300px] text-base text-gray-500 pb-5'> BSCIC, Industrial Area, plot# A 27& 28, Maria, Kishoreganj-2300,
              Bangladesh.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;