import React, { useEffect } from 'react';
import auth from "../../firebase.init"
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useToken from '../Hooks/useToken';
import Loading from '../shared/Loading/Loading';
import Footer from '../shared/Footer';


const Login = () => {

  const { register, formState: { errors }, handleSubmit } = useForm();
  const [signInWithEmailAndPassword, user, loading, error,] = useSignInWithEmailAndPassword(auth);
  const [token] = useToken(user);

  let errorElement;
  const navigate = useNavigate();


  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [ navigate, token])

  if (error) {
    errorElement = <p className='w-60 text-red-500'>{error.message}</p>
  }

  const onSubmit = data => {
    signInWithEmailAndPassword(data.email, data.password)
  }

  if (loading) {
    return <Loading loadingStatus="true"></Loading>
  }

  return (
    <>
      <div className="hero text-gray-900">
        <div className="hero-content pt-10 pb-14">
          <div style={{ boxShadow: "0 3px 8px rgb(0 0 0 / 24%)" }} className="card flex-shrink-0 w-64 sm:w-80 bg-base-100">
            <div className="card-body gap-8">
              <h4 className='text-3xl text-center font-bold'>Login</h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control">
                  <label htmlFor='email' className="label pb-1">
                    <span className="label-text ">Email <span className='text-red-500'>*</span></span>
                  </label>
                  <input id='email' type="email" placeholder="email" className="input input-bordered formInputDegine w-60"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required ?"
                      },
                      pattern: {
                        value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                        message: 'Provide a valid email'
                      }
                    })} />
                  <label className="label h-6">
                    {errors.email?.type === 'required' && <span className="label-text-alt text-red-500" >{errors.email.message}</span>
                    }
                    {errors.email?.type === 'pattern' && <span className="label-text-alt text-red-500" >{errors.email.message}</span>
                    }
                  </label>
                </div>
                <div className="form-control">
                  <label htmlFor='password' className="label pb-1">
                    <span className="label-text ">Password <span className='text-red-500'>*</span></span>
                  </label>
                  <input id='password' type="password" placeholder="password" className="input input-bordered formInputDegine w-60"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required ?"
                      },
                      minLength: {
                        value: 6,
                        message: 'Password minimum 6 Character'
                      }
                    })} />
                  <label className="label h-6">
                    {errors.password?.type === 'required' && <span className="label-text-alt text-red-500" >{errors.password.message}</span>
                    }
                    {errors.password?.type === 'minLength' && <span className="label-text-alt text-red-500" >{errors.password.message}</span>
                    }
                  </label>
                </div>
                {errorElement}
                <div className="form-control mt-5">
                  <input type="submit" value="Login" className="btn btn-primary text-white font-bold border-0"></input>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Login;