/* eslint-disable react-hooks/rules-of-hooks */
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CiCircleRemove } from "react-icons/ci"
import { toast } from 'react-toastify';


const AddProduct = () => {
    const { register, handleSubmit, reset } = useForm();
    const imageStorageKey = "bbb41293b29baeed6436287ccb9bbf00";
    const [images, setImages] = useState([]);

    const handleFileSelected = async (e) => {
        if (images.length > 3) return;
        const file = Array.from(e.target.files);
        const image = file[0];
        const formData = new FormData();
        formData.append("image", image);
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData
            });
            const result = await response.json();

            if (result.success) {
                setImages([...images, result.data.url])
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    }

    const handleUploadImageRemove = (selectImage) => {
        const restImage = images?.filter(singleImg => singleImg !== selectImage)
        setImages(restImage);
    }

    const handleAddProduct = (data) => {

        if (images.length === 0) return toast.warning("please upload at least one image");

        const newProduct = {
            ...data,
            images
        }

        fetch(`https://onlinefooddelivery.agropackagingltd.com/product`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(newProduct)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    toast.success(`Product successfully Added`)
                    reset();
                    setImages([]);
                } else {
                    console.log(data);
                    toast.error(`Something went wrong. please try an other one`);
                }
            })
    }

    return (
        <div className='flex justify-center my-10'>
            <div className="max-w-lg max-h-fit add-review-shadow py-8 px-6 sm:p-10 mx-4 sm:mx-10">
                <h2 className='text-2xl text-center mb-6 font-bold'>Add Product</h2>
                <div className='bg-white rounded-2xl'>
                    <form onSubmit={handleSubmit(handleAddProduct)}>
                        <div className="form-control mb-4">
                            <label htmlFor="name" className="label">
                                <span className="label-text font-medium">Product Name <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <input type="text" id='name' placeholder="Full Name" className="input input-bordered formInputDesgign" {...register("name")} required />
                        </div>
                        <div className="form-control mb-6">
                            <label htmlFor='description' className="label">
                                <span className="label-text font-medium">Say Something About Your Product <span className='text-secondary ml-0.5'>*</span></span>
                            </label>
                            <textarea id='description' className="textarea textarea-bordered formInputDesgign h-24 leading-normal" placeholder="Say Something ..." {...register("description")} required></textarea>
                        </div>
                        <div>
                            <p className='label-text inline-block font-medium mb-4'>Image Upload <span className='text-secondary ml-0.5'>*</span></p><br />
                            <label className='bg-green-100 py-2 px-8 text-primary border font-medium border-primary rounded-md cursor-pointer' htmlFor="filePicker1">
                                <FontAwesomeIcon icon={faCloudArrowUp}></FontAwesomeIcon>  Upload Photo
                            </label>
                            <input className='input input-bordered formInputDesgign invisible h-4 sm:h-6' type="file" onChange={handleFileSelected} id="filePicker1" name='filePicker1' />
                        </div>
                        <div className='flex flex-col gap-2.5'>
                            {images?.map((i, index) =>
                                <div key={index} className='w-fit flex items-center gap-3 p-1.5 shadow'>
                                    <img className='w-6 h-6' src={i} alt="" />
                                    <p className='text-sm '>Image {index + 1}</p>
                                    <CiCircleRemove onClick={() => handleUploadImageRemove(i)} className='ml-5 text-red-500 text-lg cursor-pointer' />
                                </div>
                            )}
                        </div>
                        <div className='flex justify-center mt-8'>
                            <input className='btn btn-primary w-full sm:w-60 text-white' type="submit" value="Add Product" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddProduct;
