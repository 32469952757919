import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faThumbsUp, faTruckArrowRight } from '@fortawesome/free-solid-svg-icons'


const Works = () => {

    const isMobile = window.innerWidth < 340;

    return (
        <div className='customContainer pb-8'>
            <h1 className={`text-3xl sm:text-4xl text-gray-900 font-bold mt-28 pb-[100px] text-center ${isMobile ? "smTitleLine" : "titleLine"}`}>Here's How We Operate</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 sm:gap-8 text-center">
                <div>
                    <FontAwesomeIcon className='text-4xl sm:text-5xl text-[#009849] mb-3' icon={faCartShopping} />
                    <h4 className='text-xl sm:text-2xl text-gray-900 font-bold sm:font-medium mb-2'>YOU PLACE YOUR ORDER</h4>
                    <p className='text-sm sm:text-base text-gray-900'>Place your order via phone, message or email, When we receive your order try our best to quick delivery.</p>
                </div>
                <div>
                    <FontAwesomeIcon className='text-4xl sm:text-5xl text-[#009849] mb-3' icon={faTruckArrowRight} />
                    <h4 className='text-xl sm:text-2xl text-gray-900 font-bold sm:font-medium mb-2'>WE PICK, PACK & DELIVER</h4>
                    <p className='text-sm sm:text-base text-gray-900' >We pick, pack & deliver your order on time in modern, delivery truck & car vehicles with GPS tracking.</p>
                </div>
                <div>
                    <FontAwesomeIcon className='text-4xl sm:text-5xl text-[#009849] mb-3' icon={faThumbsUp} />
                    <h4 className='text-xl sm:text-2xl text-gray-900 font-bold sm:font-medium mb-2'>INSTANT REPLACEMENT</h4>
                    <p className='text-sm sm:text-base text-gray-900' >If there’s something that you’re not satisfied with, we’ll replace it in any day or we trying solved your issue.</p>
                </div>
            </div>
        </div>
    );
};


export default Works;